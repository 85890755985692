import { useTranslation } from 'react-i18next';
import SimpleCard from '../../../ui/cards/SimpleCard';
import Lottie from 'lottie-react';
import '../../../styles/components/charts/pieChartComponent.scss';
import statsLottie from '../../../assets/json/lottie_animation/statsInfographicsLottie.json';
import setupTrackingLottie from '../../../assets/json/lottie_animation/setup_tracking.json';
import '../../../styles/pages/dashboard/stats/aiCheckoutTrackingStats.scss';
import { ShippingCostImpactLineChart } from './dummyStats/ShippingCostImpactLineChart';
import ColorUsageScatterChart from './dummyStats/ColorUsageScatterChart';
import ConversionRateGroupedBarChart from './dummyStats/ConversionRateGroupedBarChart';
import ProductPurchaseBarChart from './dummyStats/ProductPurchaseBarChart';
import AverageCartValueLineChart from './dummyStats/AverageCartValueLineChart';
import UserActivityBubbleChart from './dummyStats/UserActivityBubbleChart';
import { APP_ENV } from '../../../lib/constants/central-api';
import ConversionRateChart from '../../../components/charts/ConversionRate/ConversionRateChart';

type Props = {
  isTrackable: boolean;
  isTimesUp: boolean;
};

const AiCheckoutTrackingStats = ({ isTrackable, isTimesUp }: Props) => {
  const { t } = useTranslation();

  const renderCharts = () => {
    if (isTimesUp && APP_ENV === 'LIVE') {
      /* 
      return (
        <PieChartComponent
          data={chartData}
          additionalData={additionalChartData}
          width='100%'
          height={300}
          innerRadius={120}
          title={t('ConversionRate')}
          isChartVisible={chartData[0].value !== 0 || chartData[1].value !== 0} // If there is no data, don't show the chart
          comment={`${t('ConversionRateComment')} ${conversionRate?.toFixed(
            2
          )}%`}
          isChartDataVisible={false}
        /> 
      );
      */

      return (
        <SimpleCard grow={false}>
          <div className='aiCheckoutTrackingStats__card'>
            <h3>{t('TrendsText1')}</h3>
            <div className='aiCheckoutTrackingStats__card--lottie'>
              <Lottie animationData={statsLottie} loop={true} />
            </div>
          </div>
        </SimpleCard>
      );
    } else if (isTimesUp && APP_ENV !== 'LIVE') {
      return (
        <div className='dummyChart'>
          <div className='dummyChart--content'>
            <ConversionRateChart />
          </div>
          <div className='dummyChart--content'>
            <AverageCartValueLineChart
              width='100%'
              height={300}
              title={t('Average Cart Value Over Time')}
            />
            <ConversionRateGroupedBarChart
              width='100%'
              height={300}
              title={t(
                'Device-Based Conversion Rates for Electronics and Clothing'
              )}
            />
          </div>
          <div className='dummyChart--content'>
            <ProductPurchaseBarChart
              width='100%'
              height={300}
              title={t(
                'Product Purchase Distribution Across European Countries'
              )}
            />
          </div>
          <div className='dummyChart--content'>
            <ColorUsageScatterChart
              width='100%'
              height={300}
              title={t('Button Color Usage')}
            />
            <ShippingCostImpactLineChart
              width='100%'
              height={300}
              title={t('Impact of Shipping Costs on Conversion Rates')}
            />
          </div>
          <div className='dummyChart--content'>
            <UserActivityBubbleChart
              width='100%'
              height={300}
              title={t('Weekly User Activity Overview')}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className='aiCheckoutTrackingStats--container'>
      <div className='aiCheckoutTrackingStats'>
        {isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText1')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText2')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {renderCharts()}
      </div>
    </div>
  );
};

export default AiCheckoutTrackingStats;
