import { useState, useEffect } from 'react';
import {
  initialInputData,
  transformInputData,
} from '../../../../lib/constants/trackingClassesData';
import NormalInput from '../../../../ui/inputs/NormalInput';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import { useTranslation } from 'react-i18next';
import post_integration_api_call from '../../../../lib/api/integration/post_integration_api_call';
import Popup from '../../../../ui/popup/Popup';
import CssClassesWarningMsg from './CssClassesWarningMsg';
import CssClassesHelp from './CssClassesHelp';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import store from '../../../../store/store';
import { setMessage } from '../../../../store/reducers/appSlice';

interface Props {
  data: any;
  isAutofillEnabled: boolean;
  setScriptKey: (key: string) => void;
}

// Helper function to get initial values without mutating the second input object
function getInitialValues(
  configObjFromAPI: { [key: string]: any }, // The first input object with initial values
  allEmptyConfigObject: { [key: string]: any } // The second input object with empty values
): { [key: string]: any } {
  // The return type is an object with string keys and any values
  // Create a new object by spreading allEmptyConfigObject
  const newConfigObject = { ...allEmptyConfigObject };

  // Iterate over each key in the first object (configObjFromAPI)
  for (const key in configObjFromAPI) {
    // Check if the value in the first object is not null
    // and if the second object (newConfigObject) has this key
    if (configObjFromAPI[key] !== null && newConfigObject.hasOwnProperty(key)) {
      // If both conditions are met, set the value of the new object to the value from the first object
      newConfigObject[key] = configObjFromAPI[key];
    }
  }
  // Return the new object
  return newConfigObject;
}

function CssClasses({ data, setScriptKey, isAutofillEnabled }: Props) {
  const [isFormErrorWarningOpen, setIsFormErrorWarningOpen] = useState(false);
  const [isEveryInputNull, setIsEveryInputNull] = useState(true);
  const [trackingInputValues, setTrackingInputValues] = useState<any>(
    data?.length > 0
      ? getInitialValues(
          data[data?.length - 1]?.configuration,
          transformInputData(initialInputData)
        )
      : transformInputData(initialInputData)
  );

  const [isDuplicateValue, setIsDuplicateValue] = useState(false);
  const [isEmptyValue, setIsEmptyValue] = useState(false);

  const [paymentTrackingMethodComplete, setPaymentTrackingMethodComplete] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusCompletePaymentTrackingMechanism']
      )
        ? trackingInputValues['statusCompletePaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodFailed, setPaymentTrackingMethodFailed] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusFailedPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusFailedPaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodExpired, setPaymentTrackingMethodExpired] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusExpiredPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusExpiredPaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodCancel, setPaymentTrackingMethodCancel] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusCancelPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusCancelPaymentTrackingMechanism']
        : 'null'
    );

  const [
    paymentTrackingMethodCompleteRequired,
    setPaymentTrackingMethodCompleteRequired,
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  const toggleWarningPopupModal = () => {
    setIsFormErrorWarningOpen(!isFormErrorWarningOpen);
  };

  const onInputChange = (e: any, value: string) => {
    setTrackingInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  useEffect(() => {
    if (!isFormErrorWarningOpen) {
      setIsDuplicateValue(false);
      setIsEmptyValue(false);
    }
  }, [isFormErrorWarningOpen]);

  async function onConfirmHandler() {
    const autofillEnabledObj: { is_autofill_enabled: 'true' | 'false' } = {
      is_autofill_enabled: isAutofillEnabled ? 'true' : 'false',
    };
    try {
      const sendingData: any = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: isEveryInputNull
          ? { key: 'value' }
          : {
              ...trackingInputValues,
              statusCompletePaymentTrackingMechanism:
                paymentTrackingMethodComplete !== 'null' &&
                paymentTrackingMethodComplete !== null
                  ? paymentTrackingMethodComplete
                  : '',
              statusFailedPaymentTrackingMechanism:
                paymentTrackingMethodFailed !== 'null' &&
                paymentTrackingMethodFailed !== null
                  ? paymentTrackingMethodFailed
                  : '',
              statusExpiredPaymentTrackingMechanism:
                paymentTrackingMethodExpired !== 'null' &&
                paymentTrackingMethodExpired !== null
                  ? paymentTrackingMethodExpired
                  : '',
              statusCancelPaymentTrackingMechanism:
                paymentTrackingMethodCancel !== 'null' &&
                paymentTrackingMethodCancel !== null
                  ? paymentTrackingMethodCancel
                  : '',
            },
      };

      if (data?.length > 0) {
        sendingData['_method'] = 'put';
      }

      const res = await post_integration_api_call(
        sendingData,
        data?.length > 0 ? data[data?.length - 1]?.slug : undefined,
        autofillEnabledObj
      );
      if (res?.api_key) {
        setScriptKey(res?.api_key);
      }
    } catch (exception) {
      console.log('Error during authentication request');
      // submitLogToBugsnag('error', `${exception}`);
    }
  }

  function inputValidating(inputValuesObj: any) {
    if (
      !paymentTrackingMethodComplete ||
      paymentTrackingMethodComplete === 'null' ||
      paymentTrackingMethodComplete === null
    ) {
      setPaymentTrackingMethodCompleteRequired(true);
      store.dispatch(
        setMessage({
          message:
            t('TrackingMechanismStatus', {
              status: 'Complete',
            }) +
            ' ' +
            t('IsRequired'),
          messageType: 'error',
        })
      );
      return;
    }

    const valueSet = new Set();
    let isEmpty = false;
    let isDuplicate = false;

    const isEverythingNull = !Object.values(inputValuesObj).some(
      (value) => value !== ''
    );
    setIsEveryInputNull(isEverythingNull);

    for (const key in inputValuesObj) {
      const currentValue = inputValuesObj[key];

      if (valueSet.has(currentValue)) {
        isDuplicate = true;
        break;
      } else if (currentValue === '') {
        isEmpty = true;
      } else {
        valueSet.add(currentValue);
      }
    }

    if (isDuplicate) {
      setIsDuplicateValue(true);
      toggleWarningPopupModal();
    } else if (isEmpty) {
      setIsEmptyValue(true);
      toggleWarningPopupModal();
    } else {
      onConfirmHandler();
    }
  }

  function onSubmitHandler(e: any) {
    e.preventDefault();
    inputValidating(trackingInputValues);
  }

  return (
    <form
      onSubmit={onSubmitHandler}
      id='Edit-tracking-class-input-form'
      className='normalForm'
    >
      <div className='normalForm__top'>
        {/* ------------------------------------------------------- */}
        <h2>{t('GetSupport')}</h2>
        <hr />
        {/* <div className='normalForm__top--dividerLine' /> */}

        <CssClassesHelp />
      </div>
      {/* ======================================================== */}
      <div className='normalForm__center'>
        <h2>{t('ManageTrackingClassNames')}</h2>
        <hr />
        <h3 className='margin-top-2rem'>
          {t('CheckoutTrackingAddClassesInstructions1')}
        </h3>
        <ol style={{ marginLeft: '2rem' }}>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions2-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions2-2')}{' '}
          </li>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions6-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions6-2')}{' '}
          </li>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions3-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions3-2')}{' '}
          </li>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions4-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions4-2')}{' '}
          </li>
          <li>
            <strong>{t('CheckoutTrackingAddClassesInstructions5-1')}</strong>
            {t('CheckoutTrackingAddClassesInstructions5-2')}{' '}
          </li>
        </ol>
        <div className='normalForm__container3'>
          {initialInputData?.map((el: any, index: number) => {
            if (!el?.is_hidden) {
              return (
                <div className='normalForm__container3--item' key={index}>
                  <div className='normalForm__container3--item-content'>
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el?.name)]}
                      id={`add-tracking-integration-${el?.name}`}
                      value={
                        trackingInputValues ? trackingInputValues[el?.name] : ''
                      }
                      placeholder={el?.label}
                      innerPlaceholder={t('CssClassesPlaceholder')}
                      required={el?.is_required ? true : false}
                      autoFocus={index === 0 ? true : false}
                      classes={`tracking-class-${el}-input`}
                      labelClasses={`tracking-class-${el}-label`}
                      disabled={el?.is_active ? false : true}
                      title={t('CssClassInput-validation')}
                      pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      <Popup
        styles={{
          backgroundColor: '#fff',
        }}
        setTrigger={toggleWarningPopupModal}
        trigger={isFormErrorWarningOpen}
      >
        <CssClassesWarningMsg
          handler={onConfirmHandler}
          closeModal={toggleWarningPopupModal}
          mainTitle={
            isEmptyValue
              ? t('EmptyFieldsWarningTitle')
              : isDuplicateValue
              ? t('DuplicateFieldsWarningTitle')
              : ''
          }
          subTitle1={
            isEmptyValue
              ? t('EmptyFieldsWarningSubTitle1')
              : isDuplicateValue
              ? t('DuplicateFieldsWarningSubTitle1')
              : ''
          }
        />
        <div className='warning'></div>
      </Popup>

      <div className='normalForm__center'>
        {/* ------------------------------------------------------- */}
        <h2>{t('ManagePaymentStatusTracking')}</h2>
        <hr />
        <h3 className='margin-top-2rem'>
          {t('ManagePaymentStatusTrackingSub')}
        </h3>
        {/* ------------------------------------------------------- */}
        {
          <>
            {/* //*--------------------------PaymentStatus-complete----------------------------- */}
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <NormalInput
                  id={`add-tracking-integration-payment-complete`}
                  value={t('PaymentStatus-complete')}
                  placeholder={t('PaymentStatus')}
                  disabled={true}
                  style={{ color: 'green' }}
                />
              </div>
              <div className='normalForm__container1--left'>
                <NormalSelectInput
                  defaultValue={paymentTrackingMethodComplete}
                  setSelect={(input: string) => {
                    setPaymentTrackingMethodComplete(input);
                    setPaymentTrackingMethodCompleteRequired(false);
                  }}
                  required={paymentTrackingMethodCompleteRequired}
                  selectOptionArr={[
                    {
                      OptionValue: 'null',
                      OptionName: t('SelectOption'),
                      OptionDisabled: true,
                    },
                    {
                      OptionValue: 'css',
                      OptionName: t('PaymentStatusTracking-Option-1'),
                    },
                    {
                      OptionValue: 'urlParam',
                      OptionName: t('PaymentStatusTracking-Option-2'),
                    },
                    {
                      OptionValue: 'redirectURL',
                      OptionName: t('PaymentStatusTracking-Option-3'),
                    },
                  ]}
                  placeholder={t('TrackingMechanismStatus', {
                    status: 'Complete',
                  })}
                  id={'statusCompletePaymentTrackingMechanism'}
                />
              </div>
            </div>
            <div className='normalForm__container1'>
              {paymentTrackingMethodComplete === 'css' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusCompletePaymentCSSElement'),
                      ]}
                      id={`statusCompletePaymentCSSElement`}
                      value={
                        trackingInputValues
                          ? trackingInputValues[
                              'statusCompletePaymentCSSElement'
                            ]
                          : ''
                      }
                      placeholder={t('PaymentCSSElement', {
                        status: 'Complete',
                      })}
                      innerPlaceholder={t('CssClassesPlaceholder')}
                      required={true}
                      title={t('CssClassInput-validation')}
                      pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                    />
                  </div>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(
                            e,
                            'statusCompletePaymentPreDefinedText'
                          ),
                      ]}
                      id={`statusCompletePaymentPreDefinedText`}
                      value={
                        trackingInputValues
                          ? trackingInputValues[
                              'statusCompletePaymentPreDefinedText'
                            ]
                          : ''
                      }
                      placeholder={t('PaymentPreDefinedText', {
                        status: 'Complete',
                      })}
                      required={true}
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodComplete === 'urlParam' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusCompletePaymentURLparam'),
                      ]}
                      id={`statusCompletePaymentURLparam`}
                      value={
                        trackingInputValues
                          ? trackingInputValues?.statusCompletePaymentURLparam
                          : ''
                      }
                      placeholder={t('PaymentURLparam', {
                        status: 'Complete',
                      })}
                      innerPlaceholder={t('URLparamPlaceholder')}
                      required={true}
                      title={t('URLparam-validation')}
                      pattern='^[\w]+[\w\?&]*=[\w]+$'
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodComplete === 'redirectURL' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusCompletePaymentUniqueURL'),
                      ]}
                      id={`statusCompletePaymentUniqueURL`}
                      value={
                        trackingInputValues
                          ? trackingInputValues[
                              'statusCompletePaymentUniqueURL'
                            ]
                          : ''
                      }
                      placeholder={t('PaymentUniqueURL', {
                        status: 'Complete',
                      })}
                      // innerPlaceholder='https://www.example.com'
                      required={true}
                      // title={t('Expected-URL-Format-validation')}
                      // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                    />
                  </div>
                </>
              )}
            </div>

            <br />

            {/* //*--------------------------PaymentStatus-failed----------------------------- */}
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <NormalInput
                  id={`add-tracking-integration-payment-failed`}
                  value={t('PaymentStatus-failed')}
                  placeholder={t('PaymentStatus')}
                  disabled={true}
                  style={{ color: 'red' }}
                />
              </div>
              <div className='normalForm__container1--left'>
                <NormalSelectInput
                  defaultValue={paymentTrackingMethodFailed}
                  setSelect={setPaymentTrackingMethodFailed}
                  selectOptionArr={[
                    {
                      OptionValue: 'null',
                      OptionName: t('SelectOption'),
                    },
                    {
                      OptionValue: 'css',
                      OptionName: t('PaymentStatusTracking-Option-1'),
                    },
                    {
                      OptionValue: 'urlParam',
                      OptionName: t('PaymentStatusTracking-Option-2'),
                    },
                    {
                      OptionValue: 'redirectURL',
                      OptionName: t('PaymentStatusTracking-Option-3'),
                    },
                  ]}
                  placeholder={t('TrackingMechanismStatus', {
                    status: 'Failed',
                  })}
                  id={'statusFailedPaymentTrackingMechanism'}
                />
              </div>
            </div>
            <div className='normalForm__container1'>
              {paymentTrackingMethodFailed === 'css' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusFailedPaymentCSSElement'),
                      ]}
                      id={`statusFailedPaymentCSSElement`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusFailedPaymentCSSElement']
                          : ''
                      }
                      placeholder={t('PaymentCSSElement', { status: 'Failed' })}
                      innerPlaceholder={t('CssClassesPlaceholder')}
                      required={true}
                      title={t('CssClassInput-validation')}
                      pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                    />
                  </div>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusFailedPaymentPreDefinedText'),
                      ]}
                      id={`statusFailedPaymentPreDefinedText`}
                      value={
                        trackingInputValues
                          ? trackingInputValues[
                              'statusFailedPaymentPreDefinedText'
                            ]
                          : ''
                      }
                      placeholder={t('PaymentPreDefinedText', {
                        status: 'Failed',
                      })}
                      required={true}
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodFailed === 'urlParam' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) => onInputChange(e, 'statusFailedPaymentURLparam'),
                      ]}
                      id={`statusFailedPaymentURLparam`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusFailedPaymentURLparam']
                          : ''
                      }
                      placeholder={t('PaymentURLparam', {
                        status: 'Failed',
                      })}
                      innerPlaceholder={t('URLparamPlaceholder')}
                      required={true}
                      title={t('URLparam-validation')}
                      pattern='^[\w]+[\w\?&]*=[\w]+$'
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodFailed === 'redirectURL' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) => onInputChange(e, 'statusFailedPaymentUniqueURL'),
                      ]}
                      id={`statusFailedPaymentUniqueURL`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusFailedPaymentUniqueURL']
                          : ''
                      }
                      placeholder={t('PaymentUniqueURL', {
                        status: 'Failed',
                      })}
                      // innerPlaceholder='https://www.example.com'
                      required={true}
                      // title={t('Expected-URL-Format-validation')}
                      // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                    />
                  </div>
                </>
              )}
            </div>

            <br />

            {/* //*--------------------------PaymentStatus-expired----------------------------- */}
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <NormalInput
                  id={`add-tracking-integration-payment-expired`}
                  value={t('PaymentStatus-expired')}
                  placeholder={t('PaymentStatus')}
                  disabled={true}
                  style={{ color: 'orange' }}
                />
              </div>
              <div className='normalForm__container1--left'>
                <NormalSelectInput
                  defaultValue={paymentTrackingMethodExpired}
                  setSelect={setPaymentTrackingMethodExpired}
                  selectOptionArr={[
                    {
                      OptionValue: 'null',
                      OptionName: t('SelectOption'),
                    },
                    {
                      OptionValue: 'css',
                      OptionName: t('PaymentStatusTracking-Option-1'),
                    },
                    {
                      OptionValue: 'urlParam',
                      OptionName: t('PaymentStatusTracking-Option-2'),
                    },
                    {
                      OptionValue: 'redirectURL',
                      OptionName: t('PaymentStatusTracking-Option-3'),
                    },
                  ]}
                  placeholder={t('TrackingMechanismStatus', {
                    status: 'Expired',
                  })}
                  id={'statusExpiredPaymentTrackingMechanism'}
                />
              </div>
            </div>
            <div className='normalForm__container1'>
              {paymentTrackingMethodExpired === 'css' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusExpiredPaymentCSSElement'),
                      ]}
                      id={`statusExpiredPaymentCSSElement`}
                      value={
                        trackingInputValues
                          ? trackingInputValues[
                              'statusExpiredPaymentCSSElement'
                            ]
                          : ''
                      }
                      placeholder={t('PaymentCSSElement', {
                        status: 'Expired',
                      })}
                      innerPlaceholder={t('CssClassesPlaceholder')}
                      required={true}
                      title={t('CssClassInput-validation')}
                      pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                    />
                  </div>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(
                            e,
                            'statusExpiredPaymentPreDefinedText'
                          ),
                      ]}
                      id={`statusExpiredPaymentPreDefinedText`}
                      value={
                        trackingInputValues
                          ? trackingInputValues[
                              'statusExpiredPaymentPreDefinedText'
                            ]
                          : ''
                      }
                      placeholder={t('PaymentPreDefinedText', {
                        status: 'Expired',
                      })}
                      required={true}
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodExpired === 'urlParam' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) => onInputChange(e, 'statusExpiredPaymentURLparam'),
                      ]}
                      id={`statusExpiredPaymentURLparam`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusExpiredPaymentURLparam']
                          : ''
                      }
                      placeholder={t('PaymentURLparam', {
                        status: 'Expired',
                      })}
                      innerPlaceholder={t('URLparamPlaceholder')}
                      required={true}
                      title={t('URLparam-validation')}
                      pattern='^[\w]+[\w\?&]*=[\w]+$'
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodExpired === 'redirectURL' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusExpiredPaymentUniqueURL'),
                      ]}
                      id={`statusExpiredPaymentUniqueURL`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusExpiredPaymentUniqueURL']
                          : ''
                      }
                      placeholder={t('PaymentUniqueURL', {
                        status: 'Expired',
                      })}
                      // innerPlaceholder='https://www.example.com'
                      required={true}
                      // title={t('Expected-URL-Format-validation')}
                      // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                    />
                  </div>
                </>
              )}
            </div>

            <br />

            {/* //*--------------------------PaymentStatus-cancel----------------------------- */}
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <NormalInput
                  id={`add-tracking-integration-payment-cancel`}
                  value={t('PaymentStatus-cancel')}
                  placeholder={t('PaymentStatus')}
                  disabled={true}
                  style={{ color: 'grey' }}
                />
              </div>
              <div className='normalForm__container1--left'>
                <NormalSelectInput
                  defaultValue={paymentTrackingMethodCancel}
                  setSelect={setPaymentTrackingMethodCancel}
                  selectOptionArr={[
                    {
                      OptionValue: 'null',
                      OptionName: t('SelectOption'),
                    },
                    {
                      OptionValue: 'css',
                      OptionName: t('PaymentStatusTracking-Option-1'),
                    },
                    {
                      OptionValue: 'urlParam',
                      OptionName: t('PaymentStatusTracking-Option-2'),
                    },
                    {
                      OptionValue: 'redirectURL',
                      OptionName: t('PaymentStatusTracking-Option-3'),
                    },
                  ]}
                  placeholder={t('TrackingMechanismStatus', {
                    status: 'Cancel',
                  })}
                  id={'statusCancelPaymentTrackingMechanism'}
                />
              </div>
            </div>
            <div className='normalForm__container1'>
              {paymentTrackingMethodCancel === 'css' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusCancelPaymentCSSElement'),
                      ]}
                      id={`statusCancelPaymentCSSElement`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusCancelPaymentCSSElement']
                          : ''
                      }
                      placeholder={t('PaymentCSSElement', { status: 'Cancel' })}
                      innerPlaceholder={t('CssClassesPlaceholder')}
                      required={true}
                      title={t('CssClassInput-validation')}
                      pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                    />
                  </div>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) =>
                          onInputChange(e, 'statusCancelPaymentPreDefinedText'),
                      ]}
                      id={`statusCancelPaymentPreDefinedText`}
                      value={
                        trackingInputValues
                          ? trackingInputValues[
                              'statusCancelPaymentPreDefinedText'
                            ]
                          : ''
                      }
                      placeholder={t('PaymentPreDefinedText', {
                        status: 'Cancel',
                      })}
                      required={true}
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodCancel === 'urlParam' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) => onInputChange(e, 'statusCancelPaymentURLparam'),
                      ]}
                      id={`statusCancelPaymentURLparam`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusCancelPaymentURLparam']
                          : ''
                      }
                      placeholder={t('PaymentURLparam', {
                        status: 'Cancel',
                      })}
                      innerPlaceholder={t('URLparamPlaceholder')}
                      required={true}
                      title={t('URLparam-validation')}
                      pattern='^[\w]+[\w\?&]*=[\w]+$'
                    />
                  </div>
                </>
              )}
              {paymentTrackingMethodCancel === 'redirectURL' && (
                <>
                  <div className='normalForm__container1--right'>
                    <NormalInput
                      changeListeners={[
                        (e) => onInputChange(e, 'statusCancelPaymentUniqueURL'),
                      ]}
                      id={`statusCancelPaymentUniqueURL`}
                      value={
                        trackingInputValues
                          ? trackingInputValues['statusCancelPaymentUniqueURL']
                          : ''
                      }
                      placeholder={t('PaymentUniqueURL', {
                        status: 'Cancel',
                      })}
                      // innerPlaceholder='https://www.example.com'
                      required={true}
                      // title={t('Expected-URL-Format-validation')}
                      // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                    />
                  </div>
                </>
              )}
            </div>
          </>
        }
      </div>

      <div className='subPageListDelete__buttons'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            setTrackingInputValues(transformInputData(initialInputData));
            setPaymentTrackingMethodComplete('null');
            setPaymentTrackingMethodFailed('null');
            setPaymentTrackingMethodExpired('null');
            setPaymentTrackingMethodCancel('null');
            setPaymentTrackingMethodCompleteRequired(false);
          }}
          type={'button'}
          value={t('Reset')}
          buttonType={'danger'}
        />
        <MainSquareButton
          value={t('Save')}
          type='submit'
          form='Edit-tracking-class-input-form'
        />
      </div>
    </form>
  );
}
export default CssClasses;
