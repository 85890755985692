import { useTranslation } from 'react-i18next';
import '../../../styles/components/charts/chart.scss';
import NormalSelectInput from '../../../ui/inputs/NormalSelectInput';
import { useEffect, useState } from 'react';
import OverallConversionRatePieChart from './OverallConversionRatePieChart';
import LocationBasedConversionRatePieChart from './LocationBasedConversionRatePieChart';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG } from '../../../lib/allCustomSVGs';
import SingleCountryConversionRatePieChart from './SingleCountryConversionRatePieChart';
import store from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import isNotEmptyString from '../../../lib/helper-functions/isNotEmptyString';

const ConversionRateChart = () => {
  const { t } = useTranslation();
  const stats = store.getState().user?.stats;
  const [params, setParams] = useState<string>(
    '?graph=conversion_rate&time_range=all'
  );
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');
  const [response, setResponse] = useState<any>({ type: 'overall', data: {} });

  //Time filter
  const [timeFilter, setTimeFilter] = useState<string>('all');
  const headerOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: '3_months', OptionName: t('Last3Months') },
  ];

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('overall');
  const groupByOptionsArr = [
    { OptionValue: 'overall', OptionName: t('Overall') },
    { OptionValue: 'location', OptionName: t('Location') },
  ];

  //Selected country
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const renderCharts = () => {
    if (groupBy === 'overall') {
      return <OverallConversionRatePieChart apiResponse={response} />;
    } else if (groupBy === 'location' && selectedCountry === '') {
      return (
        <LocationBasedConversionRatePieChart
          apiResponse={response}
          setSelectedCountry={setSelectedCountry}
        />
      );
    } else if (groupBy === 'location' && selectedCountry !== '') {
      return (
        <div className='chart__right--content'>
          <div
            onClick={() => setSelectedCountry('')}
            className='chart__right--content-goBack'
          >
            <CustomSVGs svg={arrowHeadLeftSVG} />
            <span>{t('GoBack')}</span>
          </div>
          <SingleCountryConversionRatePieChart apiResponse={response} />
        </div>
      );
    }
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    setLoading('pending');
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (selectedCountry && selectedCountry !== '') {
          setResponse({ type: 'country', data: res });
        } else {
          setResponse({ type: groupBy, data: res });
        }
        setLoading('success');
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
      setLoading('null');
    }
  };

  useEffect(() => {
    if (groupBy === 'overall') {
      setTitle(t('OverallConversionRate'));
      setComment(t('TheOverallConversionRate'));
    } else if (groupBy === 'location' && selectedCountry === '') {
      setTitle(t('LocationBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnLocation'));
    } else if (groupBy === 'location' && selectedCountry !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedCountry}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedCountry}`);
    }
  }, [groupBy, selectedCountry]);

  useEffect(() => {
    setSelectedCountry('');
  }, [groupBy]);

  useEffect(() => {
    if (groupBy === 'overall') {
      setParams(`?graph_type=conversion_rate&time_range=${timeFilter}`);
    } else if (groupBy === 'location') {
      if (selectedCountry && selectedCountry !== '') {
        setParams(
          `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=location&group_by_value=${selectedCountry}`
        );
      } else {
        setParams(
          `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=location`
        );
      }
    }
  }, [groupBy, timeFilter, selectedCountry]);

  useEffect(() => {
    if (
      isNotEmptyString(stats.ai_tracker_api_key) &&
      isNotEmptyString(stats.ai_tracker_access_token)
    ) {
      getGraphData();
    }
  }, [params, stats]);

  return (
    <div className='chart'>
      <div className='chart__left'>
        <div className='chart__left--title'>{title}</div>
        <div className='chart__left--comment'>{comment}</div>
        <div className='chart__left--controls'>
          <div className='chart__left--controls-selections'>
            <div className='chart__left--controls-selections-title'>
              {t('GroupBy')}
            </div>
            <div className='chart__left--controls-selections-inputs'>
              <NormalSelectInput
                defaultValue={groupBy}
                setSelect={setGroupBy}
                selectOptionArr={groupByOptionsArr}
                //   placeholder={t('Time')}
                id={'ConversionRateChart-groupBy'}
              />
            </div>
          </div>
          <div className='chart__left--controls-selections'>
            <div className='chart__left--controls-selections-title'>
              {t('Filters')}
            </div>
            <div className='chart__left--controls-selections-inputs'>
              <NormalSelectInput
                defaultValue={timeFilter}
                setSelect={setTimeFilter}
                selectOptionArr={headerOptionsArr}
                placeholder={t('Time')}
                id={'ConversionRateChart-timeFilter'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='chart__right'>
        {loading === 'pending' && <LoadingSpinner />}

        {loading === 'null' && (
          <NoData
            title={t('No data available')}
            subTitle='Need more interactions to generate stats'
          />
        )}
        {loading === 'success' && renderCharts()}
      </div>
    </div>
  );
};

export default ConversionRateChart;
